<template>
  <div>
    <b-card no-body>
      <b-card header-tag="header">
        <template v-slot:header>
          <product-form-nav
            :productDetails="productDetails"
            link="faq"
            :productId="id"
          ></product-form-nav>
        </template>
        <b-card-text>
          <div class="pb-4">
            <h4>
              Product FAQ
              <span class="float-right">
                <b-button :to="`/product/faq-form/${id}`" variant="primary"
                  ><i class="las la-image"></i> New Question
                </b-button>
              </span>
            </h4>
          </div>
        </b-card-text>
        <DataTable
          ref="dt"
          :value="items"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          class="mt-0"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
        >
          <template #empty>
            <div class="text-center text-muted py-5">Questions not found.</div>
          </template>

          <Column field="order" header="Question" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.question }}
            </template>
          </Column>
          <Column field="order" header="Answer" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.answer }}
            </template>
          </Column>
          <Column field="order" header="Order" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.sort_order }}
            </template>
          </Column>
          <Column field="order" header="Status" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.status }}
            </template>
          </Column>
          <Column field="action" header="Action" :sortable="true">
            <template #body="slotProps">
              <router-link
                class="btn btn-sm btn-outline-primary"
                :to="`/product/faq-form/${id}/${slotProps.data.id}`"
              >
                <i class="las la-edit"></i>
              </router-link>
              <button
                class="btn btn-sm btn-outline-danger ml-2"
                @click="deleteFaq(slotProps.data.id)"
              >
                <i class="las la-trash"></i>
              </button>
            </template>
          </Column>
        </DataTable>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import ProductFormNav from "./components/ProductFormNav.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Swal from "sweetalert2";

//api services
import ProductService from "@/core/services/api/product/product";
import faqService from "@/core/services/api/product/faq";

export default {
  components: {
    ProductFormNav,
    DataTable,
    Column,
  },
  data() {
    return {
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      productDetails: {},
      items: [],
      filters: {},
    };
  },
  created() {
    this.getProductById();
    this.getFaqList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Products",
        route: "/products",
      },
      {
        title: "Manage Product : Option / Variant",
      },
    ]);
  },
  methods: {
    async getProductById() {
      var vm = this;
      var response = await ProductService.getProductById(this.id);
      vm.productDetails = response;
    },
    async getFaqList() {
      var vm = this;
      var response = await faqService.getProductFaq(vm.id);
      vm.items = response;
    },
    deleteFaq(id) {
      var vm = this;
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          faqService
            .deleteFaq(id)
            .then(function (response) {
              vm.getFaqList();
              let timerInterval;
              Swal.fire({
                title: response.message,
                timer: 1000,
                icon: "success",
                timerProgressBar: true,
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                  Swal.showLoading();
                  const b = Swal.getHtmlContainer().querySelector("b");
                  timerInterval = setInterval(() => {
                    b.textContent = Swal.getTimerLeft();
                  }, 100);
                },
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                }
              });
            })
            .catch(function () {});
        }
      });
    },
  },
};
</script>

<style></style>
